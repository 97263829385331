<template>
  <div class="tiketDetails" id="tiketDetails">
    <div class="td_head">
      <div class="td_head_nav" :style="colorState?'background-color:#3BD367;':''">

        <van-icon name="arrow-left" size="22" color="#ffffff" @click="revert" />
        <div class="d_f ali_c j_s p_r">
          <img class="td_collect cur_p" style="visibility: hidden;" @click="collect" :src="isCollect ? td_collect_full: td_collect_empty" alt="">
          <!--<img class="td_collect cur_p" src="./img/td_share.png" alt="" @click="share()" >-->

          <img class="td_head_more cur_p" src="./img/td_headmore.png" @click="showHeadBox = !showHeadBox">
          <!--<van-icon class="cur_p" name="weapp-nav" size="22" color="#ffffff"    />-->
          <div class="p_a td_head_box_out" v-if="showHeadBox">
            <img class="td_head_angle p_a" src="./img/td_head_angle.png">
            <div class="tdhb_item d_f ali_c bb cur_p" @click="$router.push('/')">
              <img src="./img/td_home.png">
              <span>回到首页</span>
            </div>
            <div class="tdhb_item d_f ali_c bb cur_p" @click="goIntro('/orderList',0)">
              <img src="./img/td_order.png">
              <span>我的订单</span>
            </div>
            <!--<div class="tdhb_item d_f ali_c cur_p" @click="$router.push('/collection')">
              <img src="./img/td_collect_empty.png" >
              <span>我的收藏</span>
            </div>-->

          </div>
        </div>

      </div>
      <van-swipe class="my-swipe" :show-indicators="false" :autoplay="5000" indicator-color="white" @change="onChangeVanSwipe">
        <van-swipe-item v-for="(item, index) in imgs" :key="index">
          <van-image :src="item" class="recr_swiper_item_img">
            <template v-slot:error>加载失败</template>
          </van-image>
        </van-swipe-item>
        <template #indicator>
          <div class="td_head_au">
            <div class="td_head_au_left td_head_color" v-if="videos && videos.length > 0">视频({{videos ? (current+1)+'/'+videos.length : (0/0)}})</div>
            <div class="td_head_au_right cur_p" @click="goIntro('/album')">图片({{imgs ? (current+1)+'/'+imgs.length : (0/0)}})</div>
          </div>
        </template>
      </van-swipe>
    </div>
    <div class="p_r td_c_out">
      <div class="td_c_content p_r">
        <div class="tdc_top_out td_content_out">
          <div class="d_f tdc_item ali_c bb big">
            <div class="flex_1 tdc_t_title">{{scenic.name}}</div>
            <div class="tdc_audio_btn d_f ali_c cur_p" v-if="scenic && scenic.audiobook" @click="play()">
              <img src="./img/maikefeng.png" alt="">
              <span v-if="playing">语音播放中</span>
              <span v-else>语音讲解</span>
            </div>
          </div>
          <!--购票须知-->
          <div class="d_f tdc_item ali_c bb cur_p" @click="goIntro('/buyTip')">
            <div class="flex_1 tdc_t_title_sub">{{scenic.sellerAdvise ? scenic.sellerAdvise : ''}}</div>
            <div class="tdc_right_btn d_f ali_c cur_p">
              <span>购票须知</span>
              <img src="./img/td_right.png" alt="">
            </div>
          </div>
          <!--导航-->
          <div class="d_f tdc_item ali_c" :class="$route.query.priceCalendarCount > 0 ? 'bb' : ''">
            <div class="flex_1 tdc_t_title_sub">{{scenic.address}}</div>
            <div class="tdc_right_btn daohang d_f ali_c cur_p" v-if="scenic.longitude && scenic.latitude" @click="goMap()">
              <img src="./img/td_daohang.png" alt="">
              <span>导航</span>
            </div>
          </div>
          <!--重要通知-->
          <div class="d_f tdc_item ali_c notice cur_p" @click="goIntro('/notice')" v-if="scenic.notice">
            <img class="notice_icon" src="./img/td_notice.png" alt="">
            <div class="flex_1">{{scenic.notice}}</div>
            <img class="right_icon" src="./img/td_right.png" alt="">
          </div>
          <!--景点介绍-->
          <!-- <div v-if="$route.query.priceCalendarCount > 0" class="d_f tdc_item ali_c notice intro cur_p" @click="goIntro('/intro')">
            <img class="notice_icon" src="./img/td_intro.png" alt="">
            <div class="flex_1 tdc_t_title_sub">点击这里查看商家详情</div>
            <img class="right_icon" src="./img/td_right.png" alt="">
          </div> -->
          <!--商家介绍-->
          <div class="sj">
            <div class="title"><span></span>商家介绍<span></span></div>
            <div class="content" v-html="scenic.introduce"></div>
          </div>

        </div>
        <!--景区余票-->
        <template v-if="$route.query.priceCalendarCount == 0 && (scenic.sellerQrcodeUrl || scenic.sellerWebsite) ">
          <div class="td_out_link" v-if="remainList.length">
            <h3 class="title_t">景区预约</h3>
            <div class="t_num_out clear">
              <div class="p_r t_a f_l" @click="orderPop" v-for="(item,index) in remainList.slice(0,5)" :key="index">
                <div class="tn_top d_f ali_c j_s">
                  <span>{{item.date.split('-')[1]+'-'+item.date.split('-')[2]}}</span>
                  <span>星期{{$toChinese(item.weekStr,'week')}}</span>
                </div>
                <div class="tn_text">余票</div>
                <div class="tn_num">{{item.remainTicket}}</div>
                <div class="p_a tn_tag">约</div>
              </div>
            </div>
          </div>
        </template>
        <!--票-->
        <template v-if="$route.query.priceCalendarCount>0">
          <div class="td_content_out td_tickets_out" v-for="(item,index) in tickets" :key="index">
            <div class="tdt_item">
              <div class="tdt_title">{{item.name}}</div>
              <div class="tdt_title_sub" v-if="scenicKey == snowId">网络预订须知
                <br>1、使用时间：滑雪场开放营业之日起—2022年1月30日（暂定，根据天气情况而定）；欢迎各位游客提前致电0833-5098025客服电话，确认滑雪场天气情况（如果因天气原因，导致使用期内山上积雪不满足滑雪条件，可以申请全额退款）。
                <br>3、预订方法：需提前一天预订。
                <br>4、使用方法：在雷洞坪滑雪场办公室凭姓名和电话号码兑换滑雪票。
                <br> 5、退改规则：一经兑换不可退款。
              </div>
              <!--<div class="tdt_title_sub" v-else>实名制景区，刷身份证入园</div>-->
            </div>
            <div class="tdt_item bt d_f ali_c" v-for="(sub,idx) in (item.data).slice(0,3)" :key="idx">
              <div class="tdti_left_title" @click="showDetails(sub)">
                <div class="tdti_title">{{sub.name}}</div>
                <div class="tdti_remark text_over">{{sub.remark}}</div>
                <!-- 2022-5-23新增弹出详情按钮 -->
              </div>
              <div class="flex_1 tdti_order">
                <div class="titi_price t_r">￥{{sub.sellPrice ? sub.sellPrice/100 : 0}}</div>
                <div class="tdti_order_btn cur_p" @click="order(item.name,sub,item.commodityTypeId)">预订</div>
              </div>
              <template v-if="item.isShow">
                <div class="tdt_item bt d_f ali_c" v-for="(sub,idx) in (item.data).slice(3,item.data.length)" :key="idx">
                  <div class="tdti_left_title">
                    <div class="tdti_title">{{sub.name}}</div>
                    <div class="tdti_remark text_over">{{sub.remark}}</div>
                  </div>
                  <div class="flex_1 tdti_order">
                    <div class="titi_price t_r">￥{{sub.sellPrice ? sub.sellPrice/100 : 0}}</div>
                    <div class="tdti_order_btn cur_p" @click="order(item.name,sub,item.commodityTypeId)">预订</div>
                  </div>
                </div>
              </template>
              <div class="tdt_item bt more d_f ali_c cur_p" v-if="item.data.length > 3 && !item.isShow" @click="item.isShow = true">
                <div class="tdt_title_more t_a flex_1">
                  <span>查看更多</span>
                  <img src="./img/td_t_more.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </template>
        <!--景点详情-->
        <div class="depiction">
          <h3 class="title_t">景区介绍</h3>
          <div v-html="scenic.depiction"></div>
        </div>

        <!--评论-->
        <div class="td_content_out td_comment">
          <div class="tdc_title">用户评价</div>
          <template v-if="commentArr.length>0">
            <div class="tdc_content d_f" v-for="(item,i) in commentArr.slice(0,3)" :key="i">
              <div class="tdc_user">
                <img :src="item.headPortrait" alt="">
              </div>
              <div class="flex_1 tdc_c">
                <div class="tdc_c_title">{{item.nickname}}</div>
                <div class="d_f ali_c">
                  <van-rate readonly v-model="item.starScore" :size="12" color="#FF577B" void-icon="star-o" allow-half void-color="#FF577B" />
                  <div class="flex_1 t_r tdc_c_date">{{item.criticismDate}}</div>
                </div>
                <div class="tdc_c_detail">{{item.content}}</div>
              </div>
            </div>
            <div class="tdc_content_more d_f ali_c cur_p" @click="goComment()" v-if="commentArr.length>3">
              <div class="tdc_content_more_text t_a flex_1">
                <span>查看全部用户评价</span>
                <img src="./img/td_right.png" alt="">
              </div>
            </div>
          </template>
          <template v-else>
            <div class="t_a tdc_content">
              暂无用户评价
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--点击预定出现日期弹窗-->
    <van-action-sheet v-model:show="showBuyDate">
      <div class="t_buy_date">
        <div class="head d_f ali_c">
          <div class="flex_1">{{buyTitle}}</div>
          <div class="close">
            <img class="full_w" src="./img/t_close.png" alt="" @click="closeBuyDate">
          </div>
        </div>
        <div class="red_tip">请在使用时间内前往景区</div>
        <template v-if="dateArr.length">
          <div class="tip">请选择使用日期</div>
          <div class="date_out d_f ali_c">
            <div class="over_out">
              <div class="flex_1 date_item_out">
                <template v-for="(item,index) in dateArr" :key="index">
                  <div class="date_item dis" v-if="item.dis">
                    <div class="d_f ali_c j_s">
                      <span>{{item.date}}</span>
                    </div>
                    <div class="price">不可订</div>
                    <div class="p_a tag">
                      <img class="full_w" src="./img/t_date_choose.png" alt="">
                    </div>
                  </div>
                  <!--v-if="item.dis === true ? false:true"-->
                  <div class="date_item" :id="'date'+index" v-else :class="chooseDate === index ? 'choose' : ''" @click="dateClick(index,item)">
                    <div class="d_f ali_c j_s">
                      <span>{{item.date}}</span>
                    </div>
                    <div class="price">￥{{item.sellPrice ? (item.sellPrice/100).toFixed(2) : 0}}</div>
                    <div class="p_a tag">
                      <img class="full_w" src="./img/t_date_choose.png" alt="">
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="date_more p_a d_f ali_c cur_p" @click="moreDate">
              <div class="flex_1">更多日期</div>
              <div class="date_more_icon">
                <img class="full_w" src="./img/td_right.png" alt="">
              </div>
            </div>

          </div>

        </template>
        <template v-if="timeArr.length">
          <div class="tip">请选择使用时段</div>
          <div class="time_out">
            <div class="over_out">
              <template v-for="(item,index) in timeArr" :key="index">
                <div v-if="!item.dis" :id="'date'+index" class="time_item" :class="chooseTime === index ? 'choose' : ''" @click="timeClick(index,item)">
                  <span style="margin-right: 5px">{{item.timeSlot}}</span>
                  <span>{{'￥'+(item.sellPrice ? (item.sellPrice/100).toFixed(2):0)}}</span>
                  <div class="p_a tag">
                    <img class="full_w" src="./img/t_date_choose.png" alt="">
                  </div>
                </div>
                <div v-else class="time_item dis">
                  <span>{{item.timeSlot}}</span>
                  <span>{{'￥'+(item.sellPrice ? (item.sellPrice/100).toFixed(2):0)}}</span>
                  <div class="p_a tag">
                    <img class="full_w" src="./img/t_date_choose.png" alt="">
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <div class="date_btn cur_p p_a" @click="goOrder">
          立即预订
        </div>
      </div>
    </van-action-sheet>
    <!--点击更多日期出现日历-->
    <!--:min-date="minDate"-->
    <!--:max-date="maxDate"-->
    <van-calendar title="选择使用日期" v-model:show="showDatePicker" color="#1ACC70" @confirm="confirmDate" @select="selectDate" :formatter="formatter" />

    <!--二维码弹窗-->
    <div v-if="showQrPop" class="p_f qr_pop ali_c d_f j_c">
      <div class="qr_out t_a" v-if="scenic.sellerQrcodeUrl">
        <div class="d_f ali_c j_s qt_top">
          <span>微信扫描/识别二维码</span>
          <img src="./img/td_qr_close.png" alt="二维码关闭" @click="showQrPop = false">
        </div>
        <div class="qr_img">
          <img :src="scenic.sellerQrcodeUrl" alt="二维码">
        </div>
        <div class="qt_title">{{scenic.name}}</div>
      </div>
      <div class="link_out t_a" v-if="scenic.sellerWebsite">
        <div class="lo_title">温馨提示</div>
        <div class="lo_tip">您即将前往{{scenic.name}}官方购票地址，祝您旅途愉快。</div>
        <div class="btn_out ali_c d_f j_s">
          <div class="btn_link" @click="showQrPop = false">取消</div>
          <div class="btn_link green" @click="$router.push('/outWeb')">确定</div>
        </div>
      </div>
    </div>
    <!--<loginPop v-if="isShowLoginPop" @close="isShowLoginPop = false"></loginPop>-->
    <!-- <div class="content" v-html="depiction"></div> -->
    <!-- 票详情弹窗 -->
    <van-action-sheet v-model:show="remarkShow" :title="sheetContent.title">
      <div class="ttdle" v-html="sheetContent.depiction" />
    </van-action-sheet>
  </div>
</template>
 
<script src="./tiketDetails.js">
</script>

<style scoped src="./tiketDetails.css"></style>
<style scoped lang="scss" src="./tiketDetails.scss"></style>